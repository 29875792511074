<template>
  <router-view/>
</template>

<script>
import {mapStores} from "pinia";
import {useCompanyStore} from "@/stores/company";


import { debounce } from "lodash";



export default {

  computed: {
    ...mapStores(useCompanyStore)
  },
  async created() {
    await this.companyStore.init();
  }

}


</script>

<style>

#app {
  font-size: 12px;
}

.el-icon-loading {
  color: mediumvioletred;
}

.mx-context-menu-item .label {
  cursor: default;
  margin-left: 4px;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
  border-radius: 0;
  border: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  color: transparent;
  background-color: transparent;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  transition: color .2s ease;
}

.el-scrollbar {
  .el-scrollbar__bar.is-horizontal {
    height: 10px;
  }

  .el-scrollbar__bar.is-horizontal .el-scrollbar__thumb {
    background: #272424;
    cursor: default;
    height: 10px;
  }
}

i {
  font-size: 14px;
}

.el-table .cell {
  font-size: 13px !important;
}

/** remove qq.map log */
a .csssprite {
  display: none;
}

div[draggable="false"][onresize="return;"] {
  display: none;
}

/** remove ckEditor log */
div .ck-powered-by{
  display: none;
}


</style>
